import React,{useState} from 'react'
import {postEventApplication} from '../Api'
export default function eventApplication(props) {
    const [name, setName] = useState('');
   const [regionOthers, setRegionOthers] = useState('');
    const [phone, setPhone] = useState('');
    const [loading,setLoading]=useState(false)
    const [success,setSuccess]=useState(false)
    const [region, setRegion] = useState('');
    const [open_quiz_2, setOpen_quiz_2] = useState('');
     const [open_quiz_1, setOpen_quiz_1] = useState([]);
    const [open_quiz_1_other, setOpen_quiz_1_other] = useState('');
    const [open_quiz_3, setOpen_quiz_3] = useState([]);
    const [open_quiz_3_other, setOpen_quiz_3_other] = useState('');
    const [open_quiz_4, setOpen_quiz_4] = useState('');
    const [open_quiz_5, setOpen_quiz_5] = useState('');
    const [open_quiz_6, setOpen_quiz_6] = useState('');
    const [open_quiz_7, setOpen_quiz_7] = useState('');
    const handleCheckboxChange = (setFunc) => (event) => {
      const { value, checked } = event.target;
      setFunc((prev) =>
        checked ? [...prev, value] : prev.filter((item) => item !== value)
      );
    };
  
    const handleRadioChange = (setFunc) => (event) => {
      setFunc(event.target.value);
    };
  
    const handleOtherChange = (setFunc) => (event) => {
      setFunc(event.target.value);
    };
  
  // const [open_quiz_3, setOpen_quiz_3] = useState('');

  // const [selectedClasses, setSelectedClasses] = useState([]);

  // const handleCheckboxChange = (e) => {
  //   const selectedClasses2 = e.target.value;
  //   if (e.target.checked) {
  //     setSelectedClasses([...selectedClasses, selectedClasses2]);
  //   } else {
  //     setSelectedClasses(selectedClasses.filter(pkg => pkg !== selectedClasses2));
  //   }
  // };

    const [message_quiz_1, setMessage_quiz_1] = useState('');
    const [message_quiz_2, setMessage_quiz_2] = useState('');
    // const [message_quiz_3, setMessage_quiz_3] = useState('');
    const handleSubmit = async (event) => {
        event.preventDefault();
            setLoading(true)
          var data=  {
                "title": props.title,
                "phone": phone,
                "name": name,
                        "residency": region,
                "residency_other": regionOthers,
                  "open_quiz_1": open_quiz_1.includes('Other') ? [...open_quiz_1.filter(option => option !== 'Other'), `Other: ${open_quiz_1_other}`].join(',') : open_quiz_1.join(' & '),
 
            "open_quiz_2": open_quiz_2,

 // "open_quiz_4": open_quiz_4,
  "open_quiz_3": open_quiz_3.includes('Other') ? [...open_quiz_3.filter(option => option !== 'Other'), `Other: ${open_quiz_3_other}`].join(',') : open_quiz_3.join(' & '),
  "open_quiz_4": open_quiz_4.join(' & '),
  "open_quiz_5": open_quiz_5.join(' & '),
  "open_quiz_6":open_quiz_6,
  "open_quiz_7":open_quiz_7,
  "message_quiz_1": message_quiz_1,
  "message_quiz_2": message_quiz_2
                          }
                          console.log(data)
   postEventApplication(data).then((msg)=>{
        setName('')
        setRegionOthers('')
        setPhone('')
        setRegion('')
        setRegionOthers('')
     //  setOpen_quiz_2('')
        //  setOpen_quiz_1('')
        //  setOpen_quiz_1('')
         setOpen_quiz_2('')
         setOpen_quiz_1('')

          // setOpen_quiz_4('') 
          setOpen_quiz_3('')
          setOpen_quiz_4('')
          setOpen_quiz_5('')
          setOpen_quiz_6('')
          setOpen_quiz_7('')
        //  setOpen_quiz_3('')
        setMessage_quiz_1('')
        setOpen_quiz_1_other('')
        setOpen_quiz_3_other('')
        setMessage_quiz_2('')
        // setMessage_quiz_3('')

        setLoading(false)
        if(msg!=false){
            // handleButtonClick()
            setSuccess(true)
            // window.scrollTo(0, 0);
            // props.sentData(true)
        }
       

      
       })
     }
    //  const [showDiv, setShowDiv] = useState(false);
    //  const handleButtonClick = () => {

    //     setShowDiv(true);
    //         setTimeout(()=>{
    //       setShowDiv(false);
    //     },3000)
        
    //   }
  return (
    <div style={{width:'100%',maxWidth:800,backgroundColor:!success?'#e5e5e5':'white',padding:10,borderRadius:10}}>

<div style={{position:'fixed',width:'100%',left:0,top:100,display:'flex',flexDirection:'row',justifyContent:'center',zIndex:1000}}>

{/* {showDiv && (
    <div className={`alert alert-success slide-in ${showDiv ? 'show' : ''}`} role="alert">
        Application submitted successfully
   </div>

      )} */}
      </div>
      {success==false  && <form onSubmit={handleSubmit}>


     
        <h4>Participate in the Survey </h4>
        <div className="form-group">
                  <label htmlFor="name" className="required">Name
                  
                  </label>
                  <input required value={name} onChange={(e)=>{setName(e.target.value)}} type="text" className="form-control" id="name" placeholder="Enter your full name"  />
                </div>

                <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
                <div className="form-group" style={{flex:1,minWidth:150}}>
                  <label htmlFor="phone" className="required">Phone Number</label>
                  <input required type="tel" maxLength={13} value={phone}  onChange={(e)=>{setPhone(e.target.value)}}  className="form-control" id="phone" placeholder="Enter your phone number"  />
                </div>
             
                </div>
            


    


    

      <div className="form-group">
  <label className="required"><b>1. </b>What types of products would you like to see more of on Konnect Food+? (Select up to 3)</label><br />
  
  <input
    type="checkbox"
    className="input_test"
    id="electronics_home_appliances"
    name="open_quiz_1"
    value="Electronics & home appliances like TV, fridge, washing machine, microwave"
    checked={open_quiz_1.includes("Electronics & home appliances like TV, fridge, washing machine, microwave")}
    onChange={handleCheckboxChange(setOpen_quiz_1)}
  />
  <label htmlFor="electronics_home_appliances">Electronics & home appliances like TV, fridge, washing machine, microwave</label><br />
  
  <input
    type="checkbox"
    className="input_test"
    id="personal_care_items"
    name="open_quiz_1"
    value="Personal care & cleaning items like lotions and sanitary towels."
    checked={open_quiz_1.includes("Personal care & cleaning items like lotions and sanitary towels.")}
    onChange={handleCheckboxChange(setOpen_quiz_1)}
  />
  <label htmlFor="personal_care_items">Personal care & cleaning items like lotions and sanitary towels.</label><br />
  
  <input
    type="checkbox"
    className="input_test"
    id="baby_products"
    name="open_quiz_1"
    value="Baby Products like diapers, wipes, ear buds, etc"
    checked={open_quiz_1.includes("Baby Products like diapers, wipes, ear buds, etc")}
    onChange={handleCheckboxChange(setOpen_quiz_1)}
  />
  <label htmlFor="baby_products">Baby Products like diapers, wipes, ear buds, etc</label><br />
  
  <input
    type="checkbox"
    className="input_test"
    id="fashion_items"
    name="open_quiz_1"
    value="Fashion like shirts and t-shirts"
    checked={open_quiz_1.includes("Fashion like shirts and t-shirts")}
    onChange={handleCheckboxChange(setOpen_quiz_1)}
  />
  <label htmlFor="fashion_items">Fashion like shirts and t-shirts</label><br />
  
  <input
    type="checkbox"
    className="input_test"
    id="food_drinks"
    name="open_quiz_1"
    value="Food & Drinks like yoghurt, soda, flour, sugar, rice, salt, tomatoes, onions etc"
    checked={open_quiz_1.includes("Food & Drinks like yoghurt, soda, flour, sugar, rice, salt, tomatoes, onions etc")}
    onChange={handleCheckboxChange(setOpen_quiz_1)}
  />
  <label htmlFor="food_drinks">Food & Drinks like yoghurt, soda, flour, sugar, rice, salt, tomatoes, onions etc</label><br />
  
  <input
    type="checkbox"
    className="input_test"
    id="other_product"
    name="open_quiz_1"
    value="Other"
    checked={open_quiz_1.includes("Other")}
    onChange={handleCheckboxChange(setOpen_quiz_1)}
  />
  <label htmlFor="other_product">Other (Please specify):</label>
  
  {open_quiz_1.includes("Other") && (
    <input
      type="text"
      className="form-control"
      id="other_product_specify"
      name="open_quiz_1_other"
      value={open_quiz_1_other}
      onChange={handleOtherChange(setOpen_quiz_1_other)}
    />
  )}
</div>
<div className="form-group">
  <label className="required">
    <b>2. </b>How satisfied are you with the variety of products we currently offer?
  </label><br />
  
  <input
    type="radio"
    className="input_test"
    id="very_satisfied"
    name="open_quiz_2"
    value="Very Satisfied"
    checked={open_quiz_2 === "Very Satisfied"}
    onChange={handleRadioChange(setOpen_quiz_2)}
  />
  <label htmlFor="very_satisfied">Very Satisfied</label><br />
  
  <input
    type="radio"
    className="input_test"
    id="satisfied"
    name="open_quiz_2"
    value="Satisfied"
    checked={open_quiz_2 === "Satisfied"}
    onChange={handleRadioChange(setOpen_quiz_2)}
  />
  <label htmlFor="satisfied">Satisfied</label><br />
  
  <input
    type="radio"
    className="input_test"
    id="neutral"
    name="open_quiz_2"
    value="Neutral"
    checked={open_quiz_2 === "Neutral"}
    onChange={handleRadioChange(setOpen_quiz_2)}
  />
  <label htmlFor="neutral">Neutral</label><br />
  
  <input
    type="radio"
    className="input_test"
    id="dissatisfied"
    name="open_quiz_2"
    value="Dissatisfied"
    checked={open_quiz_2 === "Dissatisfied"}
    onChange={handleRadioChange(setOpen_quiz_2)}
  />
  <label htmlFor="dissatisfied">Dissatisfied</label><br />
  
  <input
    type="radio"
    className="input_test"
    id="very_dissatisfied"
    name="open_quiz_2"
    value="Very Dissatisfied"
    checked={open_quiz_2 === "Very Dissatisfied"}
    onChange={handleRadioChange(setOpen_quiz_2)}
  />
  <label htmlFor="very_dissatisfied">Very Dissatisfied</label><br />
</div>

<div className="form-group">
  <label className="required"><b>3. </b>Which products do you regularly purchase from Konnect Food+? (Select all that apply)</label><br />
  
  <input
    type="checkbox"
    className="input_test"
    id="electronics_home_appliances_2"
    name="open_quiz_3"
    value="Electronics & home appliances like TV, fridge, washing machine, microwave"
    checked={open_quiz_3.includes("Electronics & home appliances like TV, fridge, washing machine, microwave")}
    onChange={handleCheckboxChange(setOpen_quiz_3)}
  />
  <label htmlFor="electronics_home_appliances_2">Electronics & home appliances like TV, fridge, washing machine, microwave</label><br />
  
  <input
    type="checkbox"
    className="input_test"
    id="personal_care_items_2"
    name="open_quiz_3"
    value="Personal care & cleaning items like lotions and sanitary towels."
    checked={open_quiz_3.includes("Personal care & cleaning items like lotions and sanitary towels.")}
    onChange={handleCheckboxChange(setOpen_quiz_3)}
  />
  <label htmlFor="personal_care_items_2">Personal care & cleaning items like lotions and sanitary towels.</label><br />
  
  <input
    type="checkbox"
    className="input_test"
    id="baby_products_2"
    name="open_quiz_3"
    value="Baby Products like diapers, wipes, ear buds, etc"
    checked={open_quiz_3.includes("Baby Products like diapers, wipes, ear buds, etc")}
    onChange={handleCheckboxChange(setOpen_quiz_3)}
  />
  <label htmlFor="baby_products_2">Baby Products like diapers, wipes, ear buds, etc</label><br />
  
  <input
    type="checkbox"
    className="input_test"
    id="fashion_items_2"
    name="open_quiz_3"
    value="Fashion like shirts & t-shirts"
    checked={open_quiz_3.includes("Fashion like shirts & t-shirts")}
    onChange={handleCheckboxChange(setOpen_quiz_3)}
  />
  <label htmlFor="fashion_items_2">Fashion like shirts & t-shirts</label><br />
  
  <input
    type="checkbox"
    className="input_test"
    id="food_drinks_2"
    name="open_quiz_3"
    value="Food & Drinks like yoghurt, soda, flour, sugar, rice, salt, tomatoes, onions etc"
    checked={open_quiz_3.includes("Food & Drinks like yoghurt, soda, flour, sugar, rice, salt, tomatoes, onions etc")}
    onChange={handleCheckboxChange(setOpen_quiz_3)}
  />
  <label htmlFor="food_drinks_2">Food & Drinks like yoghurt, soda, flour, sugar, rice, salt, tomatoes, onions etc</label><br />
  
  <input
    type="checkbox"
    className="input_test"
    id="other_product_2"
    name="open_quiz_3"
    value="Other"
    checked={open_quiz_3.includes("Other")}
    onChange={handleCheckboxChange(setOpen_quiz_3)}
  />
  <label htmlFor="other_product_2">Other (Please specify):</label>
  
  {open_quiz_3.includes("Other") && (
    <input
      type="text"
      className="form-control"
      id="other_product_specify_2"
      name="open_quiz_3_other"
      value={open_quiz_3_other}
      onChange={handleOtherChange(setOpen_quiz_3_other)}
    />
  )}
</div>




<div className="form-group">
  <label className="required"><b>4. </b>What would make your shopping experience on Konnect Food+ even better? (Select up to 3)</label><br />
  
  <input
    type="checkbox"
    className="input_test"
    id="faster_delivery"
    name="open_quiz_4"
    value="Faster Delivery"
    checked={open_quiz_4.includes("Faster Delivery")}
    onChange={handleCheckboxChange(setOpen_quiz_4)}
  />
  <label htmlFor="faster_delivery">Faster Delivery</label><br />
  
  <input
    type="checkbox"
    className="input_test"
    id="more_payment_options"
    name="open_quiz_4"
    value="More Payment Options in the app"
    checked={open_quiz_4.includes("More Payment Options in the app")}
    onChange={handleCheckboxChange(setOpen_quiz_4)}
  />
  <label htmlFor="more_payment_options">More Payment Options in the app</label><br />
  
  <input
    type="checkbox"
    className="input_test"
    id="easy_to_use_app"
    name="open_quiz_4"
    value="Make the app easy to use"
    checked={open_quiz_4.includes("Make the app easy to use")}
    onChange={handleCheckboxChange(setOpen_quiz_4)}
  />
  <label htmlFor="easy_to_use_app">Make the app easy to use</label><br />
  
  <input
    type="checkbox"
    className="input_test"
    id="easier_returns"
    name="open_quiz_4"
    value="Easier Returns"
    checked={open_quiz_4.includes("Easier Returns")}
    onChange={handleCheckboxChange(setOpen_quiz_4)}
  />
  <label htmlFor="easier_returns">Easier Returns</label><br />
  
  <input
    type="checkbox"
    className="input_test"
    id="enhanced_customer_support"
    name="open_quiz_4"
    value="Enhanced Customer Support"
    checked={open_quiz_4.includes("Enhanced Customer Support")}
    onChange={handleCheckboxChange(setOpen_quiz_4)}
  />
  <label htmlFor="enhanced_customer_support">Enhanced Customer Support</label><br />
</div>

<div className="form-group" style={{ flex: 1, minWidth: 150 }}>
  <label htmlFor="message_quiz_1">
    <b>5. </b> What would you like us to start offering?
  </label>
  <textarea
    value={message_quiz_1}
    onChange={(e) => setMessage_quiz_1(e.target.value)}
    className="form-control"
    id="message_quiz_1"
    placeholder="Write your suggestion here..."
    style={{ minHeight: 100 }}
  />
</div>

{/* <div className="form-group">
  <label className="required"><b>5. </b>What would make your shopping experience on Konnect Food+ even better? (Select up to 3)</label><br />

  <input
    type="checkbox"
    className="input_test"
    id="faster_delivery"
    name="open_quiz_5"
    value="Faster Delivery"
    checked={open_quiz_5.includes("Faster Delivery")}
    onChange={handleCheckboxChange(setOpen_quiz_5, 3)}
  />
  <label htmlFor="faster_delivery">Faster Delivery</label><br />

  <input
    type="checkbox"
    className="input_test"
    id="more_payment_options"
    name="open_quiz_5"
    value="More Payment Options in the app"
    checked={open_quiz_5.includes("More Payment Options in the app")}
    onChange={handleCheckboxChange(setOpen_quiz_5, 3)}
  />
  <label htmlFor="more_payment_options">More Payment Options in the app</label><br />

  <input
    type="checkbox"
    className="input_test"
    id="easy_to_use"
    name="open_quiz_5"
    value="Make the app easy to use"
    checked={open_quiz_5.includes("Make the app easy to use")}
    onChange={handleCheckboxChange(setOpen_quiz_5, 3)}
  />
  <label htmlFor="easy_to_use">Make the app easy to use</label><br />

  <input
    type="checkbox"
    className="input_test"
    id="easier_returns"
    name="open_quiz_5"
    value="Easier Returns"
    checked={open_quiz_5.includes("Easier Returns")}
    onChange={handleCheckboxChange(setOpen_quiz_5, 3)}
  />
  <label htmlFor="easier_returns">Easier Returns</label><br />

  <input
    type="checkbox"
    className="input_test"
    id="enhanced_customer_support"
    name="open_quiz_5"
    value="Enhanced Customer Support"
    checked={open_quiz_5.includes("Enhanced Customer Support")}
    onChange={handleCheckboxChange(setOpen_quiz_5, 3)}
  />
  <label htmlFor="enhanced_customer_support">Enhanced Customer Support</label><br />
</div> */}

<div className="form-group">
  <label className="required"><b>6. </b>Select the brands you like</label><br />

  <input
    type="checkbox"
    className="input_test"
    id="vitron"
    name="open_quiz_6"
    value="Vitron"
    checked={open_quiz_5.includes("Vitron")}
    onChange={handleCheckboxChange(setOpen_quiz_5)}
  />
  <label htmlFor="vitron">Vitron</label><br />

  <input
    type="checkbox"
    className="input_test"
    id="hisense"
    name="open_quiz_5"
    value="Hisense"
    checked={open_quiz_5.includes("Hisense")}
    onChange={handleCheckboxChange(setOpen_quiz_5)}
  />
  <label htmlFor="hisense">Hisense</label><br />

  <input
    type="checkbox"
    className="input_test"
    id="samsung"
    name="open_quiz_5"
    value="Samsung"
    checked={open_quiz_5.includes("Samsung")}
    onChange={handleCheckboxChange(setOpen_quiz_5)}
  />
  <label htmlFor="samsung">Samsung</label><br />

  <input
    type="checkbox"
    className="input_test"
    id="lg"
    name="open_quiz_5"
    value="LG"
    checked={open_quiz_5.includes("LG")}
    onChange={handleCheckboxChange(setOpen_quiz_5)}
  />
  <label htmlFor="lg">LG</label><br />
</div>
<div className="form-group">
  <label><b>7. </b>Any additional feedback you`d like to share? (Optional)</label><br />
  
  <textarea
    className="form-control"
    id="additional_feedback"
    name="message_quiz_2"
    rows="4"
    value={message_quiz_2}
    onChange={(e) => setMessage_quiz_2(e.target.value)}
  />
</div>

    <div className="form-group">
  <label className="required"><b>8. </b>What is your age group?</label><br />

  <input
    type="radio"
    className="input_test"
    id="below_18"
    name="open_quiz_6"
    value="Below 18"
    checked={open_quiz_6 === "Below 18"}
    onChange={(e) => setOpen_quiz_6(e.target.value)}
  />
  <label htmlFor="below_18">Below 18</label><br />

  <input
    type="radio"
    className="input_test"
    id="age_18_24"
    name="open_quiz_6"
    value="18-24"
    checked={open_quiz_6 === "18-24"}
    onChange={(e) => setOpen_quiz_6(e.target.value)}
  />
  <label htmlFor="age_18_24">18-24</label><br />

  <input
    type="radio"
    className="input_test"
    id="age_25_34"
    name="open_quiz_6"
    value="25-34"
    checked={open_quiz_6 === "25-34"}
    onChange={(e) => setOpen_quiz_6(e.target.value)}
  />
  <label htmlFor="age_25_34">25-34</label><br />

  <input
    type="radio"
    className="input_test"
    id="age_35_44"
    name="open_quiz_6"
    value="35-44"
    checked={open_quiz_6 === "35-44"}
    onChange={(e) => setOpen_quiz_6(e.target.value)}
  />
  <label htmlFor="age_35_44">35-44</label><br />

  <input
    type="radio"
    className="input_test"
    id="age_45_54"
    name="open_quiz_6"
    value="45-54"
    checked={open_quiz_6 === "45-54"}
    onChange={(e) => setOpen_quiz_6(e.target.value)}
  />
  <label htmlFor="age_45_54">45-54</label><br />

  <input
    type="radio"
    className="input_test"
    id="age_55_plus"
    name="open_quiz_6"
    value="55+"
    checked={open_quiz_6 === "55+"}
    onChange={(e) => setOpen_quiz_6(e.target.value)}
  />
  <label htmlFor="age_55_plus">55+</label><br />
</div>

{region==='Other'     && <div className="form-group">
 <label htmlFor="regionOthers" >Specify residency if `Other` is selected

                  </label>
                  <input  value={regionOthers} onChange={(e)=>{setRegionOthers(e.target.value)}} type="text" className="form-control" id="regionOthers" placeholder=""  />
                </div>}
            

                <div className="form-group">
  <label htmlFor="gender" className="required">  <b>9. </b> Gender</label>
  <select required className="form-control" id="gender" value={open_quiz_7} onChange={(e)=>{
    setOpen_quiz_7(e.target.value);
  }}>


<option value=''>-- --</option>
{[
    ['Male', 'Male'],
    ['Female', 'Female']
    // ['Not Say', 'Not Say'],
  ].map(([value, label]) => (
    <option key={value} value={value}>{label}</option>
  ))}



  </select>
</div>
<div className="form-group">
  <label htmlFor="region" className="required">  <b>10. </b> Region</label>
  <select required className="form-control" id="region" value={region} onChange={(e)=>{
    setRegion(e.target.value);
  }}>


<option value=''>-- --</option>
{[
    ['Githurai_44/45', 'Githurai 44/45'],
    ['Roysambu/Mirema', 'Roysambu/Mirema'],
    ['Zimmerman', 'Zimmerman'],
    ['Kahawa_West', 'Kahawa West'],
    ['Kahawa_Wendani', 'Kahawa Wendani'],
    ['Kasarani', 'Kasarani'],   
    ['Hunters/Mwiki/Sunton', 'Hunters/Mwiki/Sunton'],
    ['Lucky_Summer/Baba_Dogo/Ngumba', 'Lucky Summer/Baba Dogo/Ngumba'],
    ['kariobangi', 'Kariobangi'],
    ['Mowlem_Umoja_3', 'Mowlem/Umoja 3'],
    ['Umoja', 'Umoja'],
    ['Kibera', 'Kibera']
  ].map(([value, label]) => (
    <option key={value} value={value}>{label}</option>
  ))}


    <option value="Other">Other</option>

  </select>
</div>
{/* 
<div className="form-group" style={{ flex: 1, minWidth: 150 }}>
  <label htmlFor="message_quiz_2">
    <b>6. </b>What`s your take on the planned National Dialogue by President Ruto and Raila Odinga next week?
  </label>
  <textarea
    value={message_quiz_2}
    onChange={(e) => setMessage_quiz_2(e.target.value)}
    className="form-control"
    id="message_quiz_2"
    placeholder="Write your response here..."
    style={{ minHeight: 100 }}
  />
</div>

<div className="form-group" style={{ flex: 1, minWidth: 150 }}>
  <label htmlFor="message_quiz_3">
    <b>7. </b>GenZ are rejecting the dialogue. What`s your opinion?
  </label>
  <textarea
    value={message_quiz_3}
    onChange={(e) => setMessage_quiz_3(e.target.value)}
    className="form-control"
    id="message_quiz_3"
    placeholder="Write your response here..."
    style={{ minHeight: 100 }}
  />
</div> */}

{/* <div className="form-group">
  <label className="required"><b>4. </b>How likely are you to recommend these digital skills classes to others?</label><br />

  <input
    type="radio"
    className="input_test"
    id="very_likely"
    name="open_quiz_2"
    value="Very likely"
    checked={open_quiz_2 === "Very likely"}
    onChange={(e) => setopen_quiz_2(e.target.value)}
  />
  <label htmlFor="very_likely">Very likely</label><br />

  <input
    type="radio"
    className="input_test"
    id="somewhat_likely"
    name="open_quiz_2"
    value="Somewhat likely"
    checked={open_quiz_2 === "Somewhat likely"}
    onChange={(e) => setopen_quiz_2(e.target.value)}
  />
  <label htmlFor="somewhat_likely">Somewhat likely</label><br />

  <input
    type="radio"
    className="input_test"
    id="not_likely"
    name="open_quiz_2"
    value="Not likely"
    checked={open_quiz_2 === "Not likely"}
    onChange={(e) => setopen_quiz_2(e.target.value)}
  />
  <label htmlFor="not_likely">Not likely</label><br />
</div>
<div className="form-group" style={{ flex: 1, minWidth: 150 }}>
  <label htmlFor="message_quiz_1">
  <b>5. </b> Would you be more likely to attend these classes if they were offered for free? If yes, what would be your main goal? If not, please specify your reason.
  </label>
  <textarea
    value={message_quiz_1}
    onChange={(e) => setMessage_quiz_1(e.target.value)}
    className="form-control"
    id="message_quiz_1"
    placeholder="Write your response here..."
    style={{ minHeight: 100 }}
  />
</div>

<div className="form-group" style={{ flex: 1, minWidth: 150 }}>
  <label htmlFor="message_quiz_2">
  <b>6. </b> Please share any additional thoughts or suggestions you have about the proposed digital skills classes:
  </label>
  <textarea
    value={message_quiz_2}
    onChange={(e) => setMessage_quiz_2(e.target.value)}
    className="form-control"
    id="message_quiz_2"
    placeholder="Write your feedback here..."
    style={{ minHeight: 100 }}
  />
</div> */}

      {/* <div className="form-group" style={{ flex: 1, minWidth: 150 }}>
        <label htmlFor="message_quiz_2" className="required">
          What specific learning outcomes or goals do you hope to achieve by participating in this Montessori workshop?
        </label>
        <textarea
          required
         
          value={message_quiz_2}
          onChange={(e) => setMessage_quiz_2(e.target.value)}
          className="form-control"
          id="message_quiz_2"
          placeholder=""
        />
      </div> */}
      {/* <div className="form-group" style={{ flex: 1, minWidth: 150 }}>
        <label htmlFor="message_quiz_3" className="required">
          Describe how you plan to integrate Montessori principles into your current or future educational or childcare setting.
        </label>
        <textarea
          required
       
          value={message_quiz_3}
          onChange={(e) => setMessage_quiz_3(e.target.value)}
          className="form-control"
          id="message_quiz_3"
          placeholder=""
        />
      </div> */}
    <div style={{float:'right'}}>
 {loading ?<button type="button" className="btn btn-warning"
             
           
             >Loading...</button>: <button type="submit" className="btn btn-warning"
             
           
             >Submit</button>}
    </div>
 
    {/* {success && <span style={{color:'green',paddingLeft:10,fontWeight:'bold'}}>
          
    Thank you for participating in the survey!
          </span>} */}
               </form>}
             {/* {success && <font style={{padding:10,fontSize:16}}>
              
              <p>Thank you for your feedback! Your input is invaluable for us to continue improving our service quality.</p>
              <p>
As our 4th anniversary celebration continues, be sure to check out the super deals from <a href="https://food.sasakonnect.net/10-weekly-offers"><b style={{color:'orange'}}>Konnect Food+ !</b></a> 
                </p>
<p>
  The Konnect Team,
</p>
</font>} */}
               {success && (
    <div className={`alert alert-success`} role="alert">
Thank you for taking the time to complete this survey.
   </div>

      )}
         {success==true &&<div style={{width:'100%',maxWidth:1200,float:'center',display:'flex'}}>
       <a href='../../'> <button className='btn btn-outline-primary' style={{fontSize:14}}>Homepage</button>
  
        </a>
        {/* <a href='https://food.sasakonnect.net/10-weekly-offers'> <button className='btn btn-warning' style={{marginLeft:20,position:"relative",fontSize:14}}>GET SUPER DEALS
        <img alt="img" width="25" src="/images/deal.png" style={{position: "absolute", right: -30}}></img>
        </button>
  
  </a> */}
        
         </div>}
<br/>
        {/* {success && <div style={{width:'100%',padding:5,display:'flex',flexWrap:"wrap",justifyContent:'space-around'}}>
        <a href='https://food.sasakonnect.net/food-cupboard/2815-cil-blended-rice-5kg-.html'>
<img src="../../images/promo_1.png"  style={{minWidth:320,width:"25vw",padding:3}} /></a>
<a href='https://food.sasakonnect.net/drinks-beverages/313-fanta-passion-fruit-soda-2l.html'>
<img src="../../images/promo_2.png"  style={{minWidth:320,width:"25vw",padding:3}} /></a>
<a href='https://food.sasakonnect.net/food-cupboard/677-kabras-sugar-2kg.html'>
<img src="../../images/promo_3.png"  style={{minWidth:320,width:"25vw",padding:3}} /></a>
        <div  style={{minWidth:320,width:"25vw"}} > </div>
         </div>} */}
       </div>
  )
}
