/* eslint eqeqeq: 0 */
/* eslint-disable react/prop-types */
import React,{useState} from 'react'
import {scholarshipApplication} from '../Api'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
export default function Scholarship() {
    
const [stage,setStage]=useState(0)
const [name, setName] = useState('');
const [gender, setGender] = useState('');
const [loading,setLoading]=useState(false)
const [region, setRegion] = useState('');
const [regionOthers, setRegionOthers] = useState('');
const [dob, setDob] = useState('');
const [bldname, setBldname] = useState('');
const [dobCert, setDobCert] = useState(null);
const [highSchool, setHighSchool] = useState('');
const [reportCardFile, setReportCardFile] = useState(null);
const [schoolCounty, setSchoolCounty] = useState('');
const [schoolWard, setSchoolWard] = useState('');
const [liveWith, setLiveWith] = useState('');
const [liveWithOther, setLiveWithOther] = useState('');

const [selectedOption, setSelectedOption] = useState('');

const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  }; 

const [fatherFname, setFatherFname] = useState('');
const [fatherLname, setFatherLname] = useState('');
const [fatherSname, setFatherSname] = useState('');
const [fatherId, setFatherId] = useState('');
const [fatherPhone, setFatherPhone] = useState('');
const [fatherCommunity, setFatherCommunity] = useState('');

const [motherFname, setMotherFname] = useState('');
const [motherLname, setMotherLname] = useState('');
const [motherSname, setMotherSname] = useState('');
const [motherId, setMotherId] = useState('');
const [motherPhone, setMotherPhone] = useState('');
const [motherCommunity, setMotherCommunity] = useState('');

const [guardianFname, setGuardianFname] = useState('');
const [guardianLname, setGuardianLname] = useState('');
const [guardianSname, setGuardianSname] = useState('');
const [guardianId, setGuardianId] = useState('');
const [guardianPhone, setGuardianPhone] = useState('');
const [guardianCommunity, setGuardianCommunity] = useState('');
const [guardianRelationship, setGuardianRelationship] = useState('');


const [whyNeeded, setWhyNeeded] = useState('');
const [physicalImpairment, setPhysicalImpairment] = useState('');
const [physicalImpairmentDetail, setPhysicalImpairmentDetail] = useState('');
const [essay, setEssay] = useState('');
const [learnScholarship, setLearnScholarship] = useState('');
const [learnScholarshipOther, setLearnScholarshipOther] = useState('');


const [declaration, setDeclaration] = useState('');
const [extracurricular, setExtracurricular] = useState('');
const [communityEngagement, setCommunityEngagement] = useState('');
const [totalMarks, setTotalMarks] = useState('');

const [error, setError] = useState('');

const scholarshipOptions = [
    { value: 'sales_agents', label: 'Sales Agents' },
    { value: 'konnect_website', label: 'Konnect website' },
    { value: 'konnect_social', label: 'Konnect Social networks i.e Facebook, Twitter, or Instagram' },
    { value: 'Others', label: 'Others' }
  ];
  const communityOptions = [
    { value: '', label: '-- --' },
    { value: 'G44', label: 'Githurai 44' },
    { value: 'G45', label: 'Githurai 45' },
    { value: 'Zimmerman', label: 'Zimmerman' },
    { value: 'Roysambu', label: 'Roysambu/Mirema' },
    { value: 'Kahawa_West', label: 'Kahawa West' },
    { value: 'Hunters', label: 'Hunters' },
    { value: 'Mwiki', label: 'Mwiki' },
    { value: 'Sunton', label: 'Sunton' },
    { value: 'Kasarani', label: 'Kasarani' },
    { value: 'Lucky_Summer', label: 'Lucky Summer' },
    { value: 'kahawa_wendani', label: 'Kahawa Wendani' },
    { value: 'Other', label: 'Other' }
  ];
const handleBobUpload = (event) => {
  const file = event.target.files[0]; 

  if (file) {
    setDobCert(file); 
}
// console.log(dobCert)
}
const handleReportCardUpload = (e) => {
    const file = e.target.files[0];
    setReportCardFile(file);
    // console.log(reportCardFile)
}
const handleSubmit = async (event) => {
    event.preventDefault();

    if(stage==5){
        setLoading(true)
        const formData = new FormData();
        formData.append('title','Beyond-limit-Scholarship-2024');
            formData.append('name',name);
            formData.append('gender',gender);
            formData.append('dob',dob);
            formData.append('residency',region);
            formData.append('residency_other',regionOthers);
            formData.append('birthcert',dobCert);
            formData.append('bldname',bldname);

            formData.append('high_school',highSchool);
            formData.append('report_card', reportCardFile);
            formData.append('school_county',schoolCounty);
            formData.append('school_ward',schoolWard);
            formData.append('live_with',liveWith);
            formData.append('live_with_other',liveWithOther);

            formData.append('father_fname',fatherFname);
            formData.append('father_lname',fatherLname);
            formData.append('father_sname',fatherSname);
            formData.append('father_id',fatherId);
            formData.append('father_phone',fatherPhone);
            formData.append('father_community',fatherCommunity);

            formData.append('mother_fname',motherFname);
            formData.append('mother_lname',motherLname);
            formData.append('mother_sname',motherSname);
            formData.append('mother_id',motherId);
            formData.append('mother_phone',motherPhone);
            formData.append('mother_community',motherCommunity);

            formData.append('guardian_fname',guardianFname);
            formData.append('guardian_lname',guardianLname);
            formData.append('guardian_sname',guardianSname);
            formData.append('guardian_id',guardianId);
            formData.append('guardian_phone',guardianPhone);
            formData.append('guardian_community',guardianCommunity);
            formData.append('guardian_relationship',guardianRelationship);

            formData.append('why_need',whyNeeded);
            formData.append('physical_impairment',physicalImpairment);
            formData.append('physical_impairment_detail',physicalImpairmentDetail);
            formData.append('learn_about_scholarship',learnScholarship);
            formData.append('learn_about_scholarship_other',learnScholarshipOther);
            formData.append('essay',essay);         


            formData.append('community_engagement',communityEngagement);
            formData.append('extracurricular',communityEngagement);
            formData.append('total_marks_grade',totalMarks);
            formData.append('declaration',declaration);
            scholarshipApplication(formData).then(dt=>{
                if(dt?.status==201){
                  setStage(stage+1)
                  window.scrollTo(0, 0);
                }else{
                  console.log(dt.response.data)
                  const allStrings = [];
              var concatenatedString =''
              if( dt.response!=null &&  dt.response.data!=null &&  typeof dt.response!=undefined &&  typeof dt.response.data!=undefined){
                for (const key in dt.response.data) {
                  if (Object.prototype.hasOwnProperty.call(dt.response.data, key) && Array.isArray(dt.response.data[key])) {
                    const concatenatedString = key + '-' + dt.response.data[key].join(', ');
                    // console.log(concatenatedString);
                    allStrings.push(concatenatedString);
                  }
                }
                
                const finalString = allStrings.join(', ');
                concatenatedString=finalString;
    
            }
              
              // console.log(concatenatedString);
              
                  setError('Upload failed, please try again, '+concatenatedString)
                }
                     setLoading(false)
              })
                       
              
        console.log(stage)
    }else{
setStage(stage+1);
window.scrollTo(0, 0);
    }
    
}
  return (
    <div style={{display:'flex',alignItems:'center',flexWrap:'wrap',minHeight:'60vh',flexDirection:'column'}}>
            <Helmet>
  <title> {'Karibu Konnect | Konnect-Beyond-Limit-Scholarship-2024'}</title>
  <meta name="description" content='Welcome to Konnect'/>
</Helmet>

<div style={{width:'100%',textAlign:'center'}}>
            <h3 style={{color:"orange"}}>Konnect Beyond Limit Scholarship Application 2024
            </h3>
          
            </div>
            {stage==0 &&    <div style={{width:'100%',maxWidth:900,textAlign:'center'}}>

            <img src='./images/scholarship.jpg' width='100%'/>
        </div>  }
            <br/>
            {stage==0 &&   <div style={{width:'100%',maxWidth:800}}>
            <p><b>Empowering Education for our Future Leaders</b></p>
<p>
We are thrilled to announce the launch of our 2024 Konnect Beyond Limit Scholarship, designed to support our valued customers in facilitating their children`s high school education.
 The Konnect Scholarship is a testament to our commitment to education and community development.
  We understand the financial challenges that families may face when sending their children to high school,
  and we are dedicated to easing this burden for our customers. This scholarship is an investment in the potential of bright and ambitious students of our community, 
  providing them with the opportunity to access quality education and pursue their dreams.
</p>
<b>
    Application Deadline:
</b>
<br/>

The application period for the Konnect Beyond Limit Scholarship is open from 7/12/2023 to 2/1/2024. We encourage all eligible customers to submit their applications promptly. Successful applicants will be notified by 4/1/2024. 
Please ensure to upload your birth certificate and result slip as required in the application.  
<br/><br/>
<div style={{width:'100%',display:'flex',justifyContent:'center'}}><button onClick={()=>{setStage(stage+1);   window.scrollTo(0, 0);}} className='btn btn-warning' >APPLY NOW !</button></div>

            </div>}
          {stage>0 && <div style={{width:'100%',display:'flex',alignItems:'center',flexDirection:'column'}}>
            <div style={{width:'100%',maxWidth:800}}>   <h4>
                <u>
                     Application Form 
                </u>
              </h4>
            <br/>
            </div>

<div style={{width:'100%',maxWidth:800,backgroundColor:'#e5e5e5',padding:15,borderRadius:15}}>
       <div style={{display:stage==1?'block':'none'}}>
<h4>Personal Info</h4>

<form onSubmit={handleSubmit}>
<div className="form-group">
                  <label htmlFor="name" className="required">Name
                  
                  </label>
                  <input required value={name} onChange={(e)=>{setName(e.target.value)}} type="text" className="form-control" id="name" placeholder="Enter your full name"  />
                </div>
            
           
<div style={{display:'flex',flexDirection:'row'}}>

                <div className="form-group" style={{flex:1}}>
  <label htmlFor="gender" className="required">Gender</label>
  <select required className="form-control" id="gender"  
  value={gender} onChange={(e)=>{
    setGender(e.target.value);
  }}
  >
  <option value=''>-- --</option>
    <option value={'male'}>Male</option>
    <option value={'female'}>Female</option>
 
  </select>
</div>
<div style={{width:30}}></div>
<div className="form-group" style={{flex:1}}>
  <label htmlFor="dob" className="required">Date of Birth</label>
  <input
    required
    type="date"
    value={dob}
    onChange={(e) => { setDob(e.target.value) }}
    className="form-control"
    id="dob"
    placeholder="Enter your date of birth"
  />
</div>
</div>
<div className="form-group">
  <label htmlFor="region" className="required">Region</label>
  <select required className="form-control" id="region" value={region} onChange={(e)=>{
    setRegion(e.target.value);
  }}>
  {communityOptions.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
  </select>
</div>
{region=='Other' && <div className="form-group">
                  <label htmlFor="regionOthers" >Specify residency if `Other` is selected
                  
                  </label>
                  <input  value={regionOthers} onChange={(e)=>{setRegionOthers(e.target.value)}} type="text" className="form-control" id="regionOthers" placeholder=""  />
                </div>}
                <div className="form-group">
                  <label htmlFor="bldname" >Building name
                  
                  </label>
                  <input  value={bldname}  onChange={(e)=>{setBldname(e.target.value)}} type="text" className="form-control" id="bldname" placeholder=""  />
                </div>    

             
<div className="form-group">
  <label htmlFor="file" className="required">Upload Birth Certicate</label>
  <input
    required
    type="file"
    accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
    onChange={handleBobUpload}
    className="form-control"
    id="file"
  />
</div>     
                <div style={{width:'100%',display:'flex',justifyContent:"space-between"}}>
        <button className='btn btn-warning' type='button' onClick={()=>{setStage(stage-1)}}  style={{display:stage==1?'block':'block'}}>Previous</button>
        <div style={{flex:1}}></div>
        <button className='btn btn-warning' type='submit' style={{display:stage==6?'none':'block'}}>Next</button>
        </div>
       
                </form>
        </div>

        {/* ///////////////////STEP2////////////////////////////// */}
        <div style={{display:stage==2?'block':'none'}}>
<h4>Academic Info</h4>

<form onSubmit={handleSubmit}>
<div className="form-group">
        <label htmlFor="highSchool"  className="required">High School</label>
        <input
          value={highSchool}
          onChange={(e) => setHighSchool(e.target.value)}
          type="text"
          className="form-control"
          id="highSchool"
          required
          placeholder=""
        />
      </div>
      <div className="form-group">
        <label htmlFor="reportCard" className="required">Upload Your latest Report Card/ Results</label>
        <input
          required
          type="file"
          accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
          onChange={handleReportCardUpload}
          className="form-control"
          id="reportCard"
        />
      </div>
      <div className="form-group">
        <label htmlFor="schoolCounty"  className="required">School County</label>
        <input
          value={schoolCounty}
          onChange={(e) => setSchoolCounty(e.target.value)}
          type="text"
          className="form-control"
          id="schoolCounty"
          required
          placeholder=""
        />
      </div>
      <div className="form-group">
        <label htmlFor="schoolWard"  className="required">School Ward</label>
        <input
          value={schoolWard}
          onChange={(e) => setSchoolWard(e.target.value)}
          type="text"
          className="form-control"
          id="schoolWard"
          required
          placeholder=""
        />
      </div>
      <div className="form-group">
        <label htmlFor="liveWith"  className="required">I Live With</label>
        <select
        required
          value={liveWith}
          onChange={(e) => setLiveWith(e.target.value)}
          className="form-control"
          id="liveWith"
        >
               <option value="">---Select---</option>
          <option value="Both Parents">Both Parents</option>
          <option value="Mother">Mother</option>
          <option value="Father">Father</option>
          <option value="Other">Other</option>
        </select>
      </div>
    {liveWith=='Other' && <div className="form-group">
        <label htmlFor="liveWithOther">Live With Other</label>
        <input
          value={liveWithOther}
          onChange={(e) => setLiveWithOther(e.target.value)}
          type="text"
          className="form-control"
          id="liveWithOther"
          placeholder=""
        />
      </div>}
<div style={{width:'100%',display:'flex',justifyContent:"space-between"}}>
        <button className='btn btn-warning' type='button' onClick={()=>{setStage(stage-1)}}  style={{display:stage==1?'none':'block'}}>Previous</button>
        <div style={{flex:1}}></div>
        <button className='btn btn-warning' type='submit' style={{display:stage==6?'none':'block'}}>Next</button>
        </div>
       
                </form>
        </div>
        <div style={{display:stage==3?'block':'none'}}>
<h4>Parents/ Guardian</h4>


<form onSubmit={handleSubmit}>
     <div className="form-group">
        <label htmlFor="selectOption">Please enter your parents Information</label>
        <select
        required
          value={selectedOption}
          onChange={handleOptionChange}
          className="form-control"
          id="selectOption"
        >
          <option value="">Select an option</option>
          <option value="both">Both Parents</option>
          <option value="father">Father</option>
          <option value="mother">Mother</option>
          <option value="guardian">Guardian</option>
        </select>
      </div>

      {(selectedOption === 'father' || selectedOption=='both') && (
             <div>
             <h3>Father Information</h3>
             <div className="form-group">
               <label htmlFor="fatherFname">First Name</label>
               <input
                 value={fatherFname}
                 onChange={(e) => setFatherFname(e.target.value)}
                 type="text"
                 className="form-control"
                 id="fatherFname"
                 placeholder="Father First Name"
                 required
               />
             </div>
             <div className="form-group">
               <label htmlFor="fatherLname">Last Name</label>
               <input
                 value={fatherLname}
                 onChange={(e) => setFatherLname(e.target.value)}
                 type="text"
                 className="form-control"
                 id="fatherLname"
                 placeholder="Father Last Name"
                 required
               />
             </div>
             <div className="form-group">
               <label htmlFor="fatherSname">Surname</label>
               <input
                 value={fatherSname}
                 onChange={(e) => setFatherSname(e.target.value)}
                 type="text"
                 className="form-control"
                 id="fatherSname"
                 placeholder="Father Surname"
                 required
               />
             </div>
             <div className="form-group">
               <label htmlFor="fatherId">ID Number</label>
               <input
                 value={fatherId}
                 onChange={(e) => setFatherId(e.target.value)}
                 type="text"
                 className="form-control"
                 id="fatherId"
                 placeholder="Father ID"
                 required
               />
             </div>
             <div className="form-group">
               <label htmlFor="fatherPhone">Phone</label>
               <input
                 value={fatherPhone}
                 onChange={(e) => setFatherPhone(e.target.value)}
                 type="tel"
                 className="form-control"
                 id="fatherPhone"
                 placeholder="Father Phone"
                 required
               />
             </div>
             
             <div className="form-group">
        <label htmlFor="fatherCommunity">Community/Region</label>
        <select
          value={fatherCommunity}
          onChange={(e) => setFatherCommunity(e.target.value)}
          className="form-control"
          id="fatherCommunity"
          required
        >
          {communityOptions.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
      </div>
           </div>
   
      )}


{(selectedOption === 'mother'  || selectedOption=='both') &&  (
               <div>
               <h3>Mother Information</h3>
               <div className="form-group">
                 <label htmlFor="motherFname">First Name</label>
                 <input
                   value={motherFname}
                   onChange={(e) => setMotherFname(e.target.value)}
                   type="text"
                   className="form-control"
                   id="motherFname"
                   placeholder="Mother First Name"
                   required
                 />
               </div>
               <div className="form-group">
                 <label htmlFor="motherLname">Last Name</label>
                 <input
                   value={motherLname}
                   onChange={(e) => setMotherLname(e.target.value)}
                   type="text"
                   className="form-control"
                   id="motherLname"
                   placeholder="Mother Last Name"
                   required
                 />
               </div>
               <div className="form-group">
                 <label htmlFor="motherSname">Surname</label>
                 <input
                   value={motherSname}
                   onChange={(e) => setMotherSname(e.target.value)}
                   type="text"
                   className="form-control"
                   id="motherSname"
                   placeholder="Mother Surname"
                   required
                 />
               </div>
               <div className="form-group">
                 <label htmlFor="motherId">ID Number</label>
                 <input
                   value={motherId}
                   onChange={(e) => setMotherId(e.target.value)}
                   type="text"
                   className="form-control"
                   id="motherId"
                   placeholder="Mother ID"
                   required
                 />
               </div>
               <div className="form-group">
                 <label htmlFor="motherPhone">Phone</label>
                 <input
                   value={motherPhone}
                   onChange={(e) => setMotherPhone(e.target.value)}
                   type="tel"
                   className="form-control"
                   id="motherPhone"
                   placeholder="Mother Phone"
                   required
                 />
               </div>
               <div className="form-group">
        <label htmlFor="motherCommunity">Community/Region</label>
        <select
          value={motherCommunity}
          onChange={(e) => setMotherCommunity(e.target.value)}
          className="form-control"
          id="motherCommunity"
          required
        >
          {communityOptions.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
      </div>
             </div>
     
      )}

{selectedOption === 'guardian' && (
              <div>
              <h3>Guardian Information</h3>
              <div className="form-group">
                <label htmlFor="guardianFname">First Name</label>
                <input
                  value={guardianFname}
                  onChange={(e) => setGuardianFname(e.target.value)}
                  type="text"
                  className="form-control"
                  id="guardianFname"
                  placeholder="Guardian First Name"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="guardianLname">Last Name</label>
                <input
                  value={guardianLname}
                  onChange={(e) => setGuardianLname(e.target.value)}
                  type="text"
                  className="form-control"
                  id="guardianLname"
                  placeholder="Guardian Last Name"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="guardianSname">Surname</label>
                <input
                  value={guardianSname}
                  onChange={(e) => setGuardianSname(e.target.value)}
                  type="text"
                  className="form-control"
                  id="guardianSname"
                  placeholder="Guardian Surname"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="guardianId">ID</label>
                <input
                  value={guardianId}
                  onChange={(e) => setGuardianId(e.target.value)}
                  type="text"
                  className="form-control"
                  id="guardianId"
                  placeholder="Guardian ID"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="guardianPhone">Phone</label>
                <input
                  value={guardianPhone}
                  onChange={(e) => setGuardianPhone(e.target.value)}
                  type="tel"
                  className="form-control"
                  id="guardianPhone"
                  placeholder="Guardian Phone"
                  required
                />
              </div>
              <div className="form-group">
        <label htmlFor="guardianCommunity">Community/Region</label>
        <select
          value={guardianCommunity}
          onChange={(e) => setGuardianCommunity(e.target.value)}
          className="form-control"
          id="guardianCommunity"
          required
        >
          {communityOptions.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
      </div>
              <div className="form-group">
                <label htmlFor="guardianRelationship">Guardian Relationship</label>
                <input 
                  value={guardianRelationship}
                  onChange={(e) => setGuardianRelationship(e.target.value)}
                  type="text"
                  className="form-control"
                  id="guardianRelationship"
                  placeholder="Guardian Relationship"
                  required
                />
              </div>
            </div>
    
      )}    
<div style={{width:'100%',display:'flex',justifyContent:"space-between"}}>
        <button className='btn btn-warning' type='button' onClick={()=>{setStage(stage-1)}}  style={{display:stage==1?'none':'block'}}>Previous</button>
        <div style={{flex:1}}></div>
        <button className='btn btn-warning' type='submit' style={{display:stage==6?'none':'block'}}>Next</button>
        </div>
       
                </form>
        </div>
        <div style={{display:stage==4?'block':'none'}}>
<h4>Other Details: PART A</h4>
<form onSubmit={handleSubmit}>
<div className="form-group">
        <label htmlFor="whyNeeded">Why do you need this Scholarship</label>
        <input
          value={whyNeeded}
          onChange={(e) => setWhyNeeded(e.target.value)}
          type="text"
          className="form-control"
          id="whyNeeded"
          required
          placeholder=""
        />
      </div>
      <div className="form-group">
        <label htmlFor="physicalImpairment">Are you Physical Impaired?</label>
        <select
          value={physicalImpairment}
          onChange={(e) => setPhysicalImpairment(e.target.value)}
          className="form-control"
          id="physicalImpairment"
          required
        >
          <option value="">--Select--</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
        </div>
    {physicalImpairment=='Yes' &&  <div className="form-group">
        <label htmlFor="physicalImpairmentDetail">Physical Impairment Detail</label>
        <textarea
          value={physicalImpairmentDetail}
          onChange={(e) => setPhysicalImpairmentDetail(e.target.value)}
          className="form-control"
          id="physicalImpairmentDetail"
          required
          placeholder=""
        />
      </div>}
      <div className="form-group">
        <label htmlFor="essay">Write a  short essay about yourself</label>
        <textarea
          value={essay}
          onChange={(e) => setEssay(e.target.value)}
          className="form-control"
          id="essay"
          required
          placeholder=""
        />
      </div>
        <div className="form-group">
        <label htmlFor="learnScholarship">Where did you Learn about Scholarship?</label>
        <select
          value={learnScholarship}
          onChange={(e) => setLearnScholarship(e.target.value)}
          className="form-control"
          id="learnScholarship"
          required
        >
          <option value="">--Select--</option>
          {scholarshipOptions.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
      </div>
   {learnScholarship=='Others' &&   <div className="form-group">
        <label htmlFor="learnScholarshipOther">Specify where if `other`</label>
        <input
          value={learnScholarshipOther}
          onChange={(e) => setLearnScholarshipOther(e.target.value)}
          type="text"
          className="form-control"
          id="learnScholarshipOther"
          required
          placeholder=""
        />
      </div>}

<div style={{width:'100%',display:'flex',justifyContent:"space-between"}}>
        <button className='btn btn-warning' type='button' onClick={()=>{setStage(stage-1)}}  style={{display:stage==1?'none':'block'}}>Previous</button>
        <div style={{flex:1}}></div>
        <button className='btn btn-warning' type='submit' style={{display:stage==6?'none':'block'}}>Next</button>
        </div></form>
        </div>
        <div style={{display:stage==5?'block':'none'}}>
<h4>Other Details: PART B</h4>
<form onSubmit={handleSubmit}>

      <div className="form-group">
        <label htmlFor="extracurricular">List any extracurricular activities you are involved in.</label>
        <textarea
          value={extracurricular}
          onChange={(e) => setExtracurricular(e.target.value)}
          className="form-control"
          id="extracurricular"
          required
          placeholder=""
        />
      </div>
      <div className="form-group">
        <label htmlFor="communityEngagement">Describe any community service or engagement you have participated in.</label>
        <textarea
          value={communityEngagement}
          onChange={(e) => setCommunityEngagement(e.target.value)}
          className="form-control"
          id="communityEngagement"
          required
          placeholder=""
        />
      </div>
      <div className="form-group">
        <label htmlFor="totalMarks">Enter your Total Marks /Grade</label>
        <input
          value={totalMarks}
          onChange={(e) => setTotalMarks(e.target.value)}
          type="text"
          className="form-control"
          id="totalMarks"
          required
          placeholder=""
        />
      </div>
    
      <div className="form-group">
         <label htmlFor="declaration">Declaration</label>
         <br/>
       I  <input  value={declaration}
          onChange={(e) => setDeclaration(e.target.value)}
        //   className="form-control"
          id="declaration"

          required
          placeholder="Enter your name"/> 
          Solemnly declare that all the information provided in this scholarship form is truthful, accurate, and complete to the best of my knowledge and belief.
       
       
      </div>
      {error!='' && <p style={{width:'100%',textAlign:'center',color:'red'}}>{error}</p>}
<div style={{width:'100%',display:'flex',justifyContent:"space-between"}}>
        <button className='btn btn-warning' type='button' onClick={()=>{setStage(stage-1)}}  style={{display:stage==1?'none':'block'}}>Previous</button>
        <div style={{flex:1}}></div>
        {loading ?<button type="button" className="btn btn-warning"
             
           
             >Loading...</button>:    <button className='btn btn-warning' type='submit' style={{display:stage==6?'none':'block'}}>Submit</button>
            }
        </div></form>
        </div>
        {stage== 6 && <div style={{textAlign:'center',minHeight:300}}>
            <br/>
            <h4 style={{color:'green'}}>Your Application has been Submitted</h4>
            <h5 style={{color:'black'}}>Thank you !</h5>
<p>
<br/>
<Link to={'../../'}>
<button className='btn btn-warning'>Go back to homepage</button></Link> 
</p>

{/* <div style={{width:'100%',display:'flex',justifyContent:"space-between"}}>
        <button className='btn btn-warning' type='button' onClick={()=>{setStage(stage-1)}}  style={{display:stage==1?'none':'block'}}>Previous</button>
        <div style={{flex:1}}></div>

        </div> */}
        </div>}

        </div>

</div>}
    </div>
  )
}
